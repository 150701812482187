import { type ApiAttributeFlags, type ApiObjectWithAttributes } from '../types'

export const extractAttribute = (data: ApiObjectWithAttributes | undefined, key: string) => {
  const isFlagKey = key.startsWith('is_')

  if (!data?.attributes) {
    return null
  }

  const attribute = data.attributes.find((attr) => {
    if (isFlagKey) {
      return attr[key as keyof ApiAttributeFlags] === true
    }

    return attr.slug === key
  })

  return attribute
}
