import { euiCanAnimate, type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    sidebar: css`
      background-color: ${euiTheme.colors.emptyShade};
      top: 0;
      left: 0;
      height: 100dvh;
      border-right: 1px solid ${euiTheme.colors.lightShade};
      ${euiCanAnimate} {
        transition: min-width ${euiTheme.animation.normal};
      }
    `,
    resizeButton: css`
      position: absolute;
      top: 0;
      right: ${euiTheme.size.s};
    `,
  }
}
