import { useUserPermissions } from '../model'
import { type ProtectProps } from './types'

const Protect: ReactFc<ProtectProps> = ({ children, permission }) => {
  const { permissions } = useUserPermissions()

  if (!permissions[permission]) {
    return null
  }

  return children
}

export { Protect }
