import { API_SKU_VALUES, buildRequestApi, HTTP_METHOD } from '@shared/api'
import { type ApiAddSku, type BaseObjectData } from '@shared/api/types'

const addSkuApi = (body: ApiAddSku) => {
  return buildRequestApi<BaseObjectData, Mutable<ApiAddSku>>(
    API_SKU_VALUES,
    'Ошибка при добавлении товара',
    {
      method: HTTP_METHOD.POST,
      body,
    },
  )()
}

export { addSkuApi }
