import { type Category } from '@entities/catalog'

import { type CategoryFilterTreeItem } from '../types'

export const createCategoryFilterTree = (
  categories: Category[],
  parent: Category['parent'] = null,
): CategoryFilterTreeItem[] => {
  return categories
    .filter((category) => {
      return category.parent === parent
    })
    .map((category) => {
      const { id, name, skus_count, isExpanded, childNodes, attributes_count } = category

      return {
        id,
        name,
        skus_count,
        attributes_count,
        isExpanded,
        childNodes,
        ...(childNodes.length && {
          children: createCategoryFilterTree(categories, id),
        }),
      }
    })
}
