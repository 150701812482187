import { EuiSkeletonRectangle } from '@elastic/eui'
import { ELEMENTS_S_WIDTH } from '@shared/config'
import { usePageTitle } from '@shared/hooks'

export const DynamicTitle = () => {
  const { isLoading, title } = usePageTitle()

  if (isLoading) {
    return <EuiSkeletonRectangle width={ELEMENTS_S_WIDTH} isLoading={isLoading} />
  }

  return <>{title}</>
}
