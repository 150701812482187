import {
  $api,
  API_QUERY_KEY,
  ATTRIBUTE_FLAGS,
  extractAttribute,
  queryClient,
  useObjectGroupApi,
} from '@shared/api'
import { MESSAGE } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { type UpdateAttributeGroupFormInputs } from './types'

export const useUpdateAttributeGroup = (id: number) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const methods = useForm<UpdateAttributeGroupFormInputs>()

  const attributeGroup = useObjectGroupApi(id)
  const nameAttribute = extractAttribute(attributeGroup.data, ATTRIBUTE_FLAGS.NAME)
  const name = nameAttribute?.value
  const nameValueId = nameAttribute?.value_id

  useEffect(() => {
    methods.reset({
      name,
      order: attributeGroup.data?.order || undefined,
    })
  }, [attributeGroup.data])

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  // TODO: Убрать после обновления object_group_value в API
  const rename = $api.useMutation('patch', '/attribute_values/{id}/', {
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: MESSAGE.SERVER_ERROR,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY.ATTRIBUTE_GROUP_LIST] })

      queryClient.invalidateQueries({
        queryKey: ['get', '/object_groups/{id}/', { params: { path: { id: id } } }],
      })

      closeModal()
    },
  })

  const changeOrder = $api.useMutation('patch', '/object_groups/{id}/')

  const updateGroup = methods.handleSubmit(({ name, order }) => {
    if (attributeGroup.data?.order !== order) {
      changeOrder.mutate({
        params: {
          path: {
            id,
          },
        },
        body: {
          order,
        },
      })
    }

    rename.mutate({
      params: {
        path: {
          id: nameValueId!,
        },
      },
      body: {
        value: name,
      },
    })
  })

  const isSubmitting = rename.isPending

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    updateGroup,
    isSubmitting,
  }
}
