import { SkuAttributesTab, SkuFilesTab } from '@features/catalog'

const SKU_TABS_LIST = [
  {
    id: 'attributes',
    name: 'Атрибуты',
    content: <SkuAttributesTab />,
  },
  {
    id: 'imagesAndFiles',
    name: 'Изображения и файлы',
    content: <SkuFilesTab />,
  },
]

export { SKU_TABS_LIST }
