import { EuiButton, EuiEmptyPrompt, EuiPageSection } from '@elastic/eui'
import { MESSAGE, PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { minHViewport } from '@shared/ui/styles'
import { Link } from 'react-router-dom'

export const ErrorPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.ERROR.title })

  return (
    <Layout>
      <EuiPageSection alignment="center" grow css={minHViewport}>
        <EuiEmptyPrompt
          title={<h2>{PAGE_TITLES.ERROR.title}</h2>}
          body={<p>{MESSAGE.NO_PAGE_ERROR}</p>}
          actions={
            <Link to="/">
              <EuiButton fill>{MESSAGE.BACK_MAIN}</EuiButton>
            </Link>
          }
        />
      </EuiPageSection>
    </Layout>
  )
}
