import { type StoreApi, type UseBoundStore } from 'zustand'

import { createNewObject } from '../utils'
import { type WithSelectors } from './types'

export const createStoreSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>
  store.use = createNewObject()

  for (const key of Object.keys(store.getState())) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, arrow-body-style
    ;(store.use as any)[key] = () => store((store) => store[key as keyof typeof store])
  }

  return store
}
