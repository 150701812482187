import { EuiSkeletonLoading, EuiSkeletonText, EuiText, EuiTreeView } from '@elastic/eui'
import { useCategoryListApi } from '@entities/catalog'
import { API_ATTRIBUTE_MODEL, useAttributeListApi } from '@shared/api'
import { MESSAGE } from '@shared/config'

import { useCategoryFilter } from '../model'
import { type CategoryFilterProps } from '../types'
import { getCategoryFilterTree } from './category-filter-tree'
import { treeWrapper } from './styles'

export const CategoryFilter: ReactFc<CategoryFilterProps> = ({
  enableCheckbox = false,
  enableRoot = false,
  isAttributesCategory = false,
}) => {
  const { isLoading } = useCategoryListApi()
  const { treeItems, search } = useCategoryFilter()
  const { data: attributes } = useAttributeListApi({ model: API_ATTRIBUTE_MODEL.SKU })

  if (!isLoading && treeItems.length === 0) {
    if (search === '') {
      return (
        <EuiText size="s" color="subdued">
          Категории отсутствуют
        </EuiText>
      )
    }

    return (
      <EuiText size="s" color="subdued">
        {MESSAGE.EMPTY}
      </EuiText>
    )
  }

  return (
    <EuiSkeletonLoading
      isLoading={isLoading}
      contentAriaLabel="Фильтр по категориям"
      loadingContent={<EuiSkeletonText size="m" lines={6} />}
      css={treeWrapper}
      loadedContent={
        <EuiTreeView
          key={search} // Добавляем ключ для перерисовки компонента во время поиска
          showExpansionArrows={!isAttributesCategory}
          items={getCategoryFilterTree({
            items: treeItems,
            enableCheckbox,
            enableRoot: enableRoot,
            isAttributesCategory,
            totalAttributesCount: attributes?.count || 0,
          })}
        />
      }
    />
  )
}
