import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiTab, EuiTabs } from '@elastic/eui'
import { useTabs } from '@shared/hooks'
import { minHFull } from '@shared/ui/styles'

import { SKU_TABS_LIST } from './sku-tabs-list'

export const SkuTabs: ReactFc = () => {
  const { selectedTabId, selectedTabContent, onSelectedTabChanged } = useTabs(
    SKU_TABS_LIST,
    SKU_TABS_LIST[0].id,
  )

  const renderTabs = () => {
    return SKU_TABS_LIST.map((tab, index) => {
      return (
        <EuiTab
          key={index}
          onClick={() => {
            return onSelectedTabChanged(tab.id)
          }}
          isSelected={tab.id === selectedTabId}
        >
          {tab.name}
        </EuiTab>
      )
    })
  }

  return (
    <EuiPanel paddingSize="none">
      <EuiFlexGroup direction="column" gutterSize="none" css={minHFull}>
        <EuiFlexItem grow={false}>
          <EuiPanel hasShadow={false}>
            <EuiTabs>{renderTabs()}</EuiTabs>
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel color="subdued">{selectedTabContent}</EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
