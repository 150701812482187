import { API_ATTRIBUTE_TYPE } from '@shared/api'

import { type AttributeTypeMap } from './types'

export const attributeTypeMap: AttributeTypeMap = {
  [API_ATTRIBUTE_TYPE.TEXT]: 'Текст',
  [API_ATTRIBUTE_TYPE.LIST]: 'Список',
  [API_ATTRIBUTE_TYPE.BOOL]: 'Логический',
  [API_ATTRIBUTE_TYPE.NESTED_LIST]: 'Вложенный список',
  [API_ATTRIBUTE_TYPE.NUMBER]: 'Число',
  [API_ATTRIBUTE_TYPE.FILE]: 'Файл',
}
