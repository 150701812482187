import { API_OBJECT_GROUP, API_QUERY_KEY, useObjectGroupListApi } from '@shared/api'

export const useCatalogListApi = () => {
  const catalogs = useObjectGroupListApi(API_OBJECT_GROUP.CATEGORY, [API_QUERY_KEY.CATALOG_LIST])

  const hasCatalogs = (catalogs.data && catalogs.data.length > 0) || false

  return {
    ...catalogs,
    hasCatalogs,
  }
}
