import { EuiLoadingSpinner, EuiText } from '@elastic/eui'
import { $api } from '@shared/api'
import { MESSAGE, ROUTES } from '@shared/config'
import { deleteCookie } from '@shared/lib'
import { Navigate } from 'react-router-dom'

export const LogoutMessage: ReactFc = () => {
  const { isSuccess, isError } = $api.useQuery('post', '/logout/')

  if (isSuccess) {
    deleteCookie('csrftoken')

    return <Navigate to={ROUTES.LOGIN.path} />
  }

  if (isError) {
    return <EuiText size="xs">{MESSAGE.SERVER_ERROR}</EuiText>
  }

  return <EuiLoadingSpinner size="s" />
}
