import { API_QUERY_KEY_OPENAPI, queryClient } from '@shared/api'
import { MESSAGE } from '@shared/config'
import { useMutation } from '@tanstack/react-query'

import { updateAttribute } from '../api'
import { type UseUpdateAttributesArgs, type UseUpdateAttributesOnSubmitArg } from './types'

const useUpdateAttributes = ({ attributeIds, methods }: UseUpdateAttributesArgs) => {
  const mutation = useMutation({
    mutationFn: async (body: UseUpdateAttributesOnSubmitArg) => {
      return attributeIds.map((id) => {
        return updateAttribute({ id, body })
      })
    },
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: MESSAGE.SERVER_ERROR,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_QUERY_KEY_OPENAPI.ATTRIBUTE_LIST],
      })
    },
  })

  return { mutation, isSubmitting: mutation.isPending }
}

export { useUpdateAttributes }
