import { Sidebar } from '@shared/ui/sidebar'

import { AttributesSidebarForm } from './attributes-sidebar-form'

export const AttributesSidebar: ReactFc = () => {
  return (
    <Sidebar isOpen>
      <AttributesSidebarForm />
    </Sidebar>
  )
}
