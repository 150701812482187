import { EuiImage } from '@elastic/eui'

import { NoImage } from './no-image'
import { type ImageProps } from './types'

export const Image: ReactFc<ImageProps> = (props) => {
  const { src, width, height } = props

  if (src) {
    return <EuiImage {...props} />
  }

  return <NoImage width={width} height={height} />
}
