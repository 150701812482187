import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel } from '@elastic/eui'
import { useStyles } from '@shared/ui/styles'

import { getStyles, noImagePanelStyle } from './styles'
import { type NoImageProps } from './types'

export const FileImage: ReactFc<NoImageProps> = ({ width, height }) => {
  const styles = useStyles(getStyles)

  return (
    <EuiPanel
      paddingSize="none"
      hasShadow={false}
      borderRadius="none"
      css={noImagePanelStyle(width, height)}
    >
      <EuiFlexGroup alignItems="center" justifyContent="spaceAround" css={styles.group}>
        <EuiFlexItem grow={false} css={styles.item}>
          <EuiIcon type="document" css={styles.icon} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
