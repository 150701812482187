import {
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiPanel,
} from '@elastic/eui'
import { Accordion } from '@shared/ui/accordion'
import { fontSemiBold } from '@shared/ui/styles'

import { useSkuDraggableList } from '../model'
import { SkuAttributesList } from './sku-attributes-list'

export const SkuDraggableList: ReactFc = () => {
  const [list, onDragEnd] = useSkuDraggableList()

  return (
    <EuiDragDropContext onDragEnd={onDragEnd}>
      <EuiDroppable droppableId="attributes">
        {list.map(({ id, name, attributes }, idx) => {
          const draggableId = `attribute-group-${id}`

          return (
            <EuiDraggable
              key={id}
              index={idx}
              draggableId={draggableId}
              customDragHandle
              hasInteractiveChildren
            >
              {(provided) => {
                return (
                  <EuiPanel color="transparent" paddingSize="none">
                    <EuiPanel hasShadow={false} paddingSize="s">
                      <Accordion
                        id={draggableId}
                        buttonContent={
                          <EuiFlexGroup gutterSize="none">
                            <EuiFlexItem grow={false}>
                              <EuiPanel
                                color="transparent"
                                paddingSize="s"
                                {...provided.dragHandleProps}
                              >
                                <EuiIcon type="grab" size="l" />
                              </EuiPanel>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiPanel color="transparent" paddingSize="s" css={fontSemiBold}>
                                {name}
                              </EuiPanel>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        }
                      >
                        <SkuAttributesList attributes={attributes} />
                      </Accordion>
                    </EuiPanel>
                    <EuiHorizontalRule margin="none" />
                  </EuiPanel>
                )
              }}
            </EuiDraggable>
          )
        })}
      </EuiDroppable>
    </EuiDragDropContext>
  )
}
