import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { CatalogActions, CatalogSidebarToggle, CatalogViewToggle } from '@features/catalog'
import { PLACEHOLDER } from '@shared/config'
import { Search } from '@shared/ui'
import { noShadow, useStyles } from '@shared/ui/styles'

import { getStyles } from './styles'

export const CatalogHeader: ReactFc = () => {
  const styles = useStyles(getStyles)

  return (
    <EuiHeader css={[styles.header, noShadow]}>
      <EuiHeaderSection css={styles.headerSection}>
        <EuiHeaderSectionItem>
          <CatalogSidebarToggle />
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <Search searchParam="search" placeholder={PLACEHOLDER.FIND_SKU} />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>

      <EuiHeaderSection css={styles.headerSection}>
        <EuiHeaderSectionItem>
          <CatalogActions />
        </EuiHeaderSectionItem>

        <EuiHeaderSectionItem>
          <CatalogViewToggle />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}
