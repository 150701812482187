import { number, route } from 'react-router-typesafe-routes/dom'

import { APP_NAME } from './constans'

export const ROUTES = {
  CATALOG: route(
    'catalog',
    {
      searchParams: {
        category: number().array(), // Параметр category, содержащий id категорий каталога
      },
    },
    {
      SKU: route(
        ':id',
        {
          params: {
            id: number().defined(),
          },
        },
        {
          PRINT: route('print'),
        },
      ),
    },
  ),
  DAM: route('dam'),
  SETTINGS: route(
    'settings',
    {},
    {
      ATTRIBUTE_GROUPS: route(
        'attribute-groups',
        {},
        {
          GROUP: route(':id', {
            params: {
              id: number().defined(),
            },
          }),
        },
      ),
      ATTRIBUTES: route(
        'attributes',
        {},
        {
          ATTRIBUTE: route(':id', {
            params: {
              id: number().defined(),
            },
          }),
        },
      ),
      CATEGORIZATION: route('categorization'),
      CLUSTERING: route('clustering'),
      CURRENCIES_AND_PRICES: route('currencies-and-prices'),
      INFO_MODELS: route('info-models'),
      RELATED_PRODUCTS: route('related-products'),
      RULES: route('rules'),
      TAXONOMY: route('taxonomy'),
      TEMPLATES: route('templates'),
      UNITS: route('units'),
      VALUE_LISTS: route('value-lists'),
    },
  ),
  LOGIN: route('login'),
  LOGOUT: route('logout'),
  PERMISSION_ERROR: route('permission-error'),
  ERROR: route('*'),
}

export const PAGE_TITLES = {
  CATALOG: {
    title: 'Каталог',
  },
  SETTINGS: {
    title: 'Настройки',
    ATTRIBUTES: { title: 'Атрибуты' },
    ATTRIBUTE_GROUPS: { title: 'Группы атрибутов' },
    CATEGORIZATION: { title: 'Категоризация' },
    CLUSTERING: { title: 'Кластеризация' },
    CURRENCIES_AND_PRICES: { title: 'Валюты и цены' },
    INFO_MODELS: { title: 'Инфомодели' },
    RELATED_PRODUCTS: { title: 'Связанные товары' },
    RULES: { title: 'Правила' },
    TAXONOMY: { title: 'Таксономия' },
    TEMPLATES: { title: 'Шаблоны' },
    UNITS: { title: 'Ед. измерений' },
    VALUE_LISTS: { title: 'Списки значений' },
  },
  LOGIN: { title: 'Авторизация' },
  LOGOUT: { title: 'Выход из системы' },
  ERROR: { title: 'Страница не найдена' },
  PERMISSION_ERROR: { title: 'Недостаточно прав' },
  SUFFIX: ` | ${APP_NAME}`,
  LOADING: `…`,
}
