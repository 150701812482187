import { EuiFilterGroup, EuiHeaderSectionItem } from '@elastic/eui'
import { CATALOG_VIEW } from '@shared/config'
import { useStyles } from '@shared/ui/styles'

import { CatalogToggleViewButton } from './catalog-toggle-view-button'
import { getStyles } from './styles'

export const CatalogViewToggle: ReactFc = () => {
  const styles = useStyles(getStyles)

  return (
    <EuiHeaderSectionItem>
      <EuiFilterGroup css={styles.group} compressed>
        <CatalogToggleViewButton viewType={CATALOG_VIEW.CARDS} iconType="filter" />
        <CatalogToggleViewButton viewType={CATALOG_VIEW.TABLE} iconType="editorTable" />
      </EuiFilterGroup>
    </EuiHeaderSectionItem>
  )
}
