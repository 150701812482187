export const getArrayParam = <T>(param: T) => {
  if (!param) {
    return
  }

  if (typeof param === 'string') {
    return param.split(',')
  }

  return param
}
