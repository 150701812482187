import { useAttributeFilterOptions } from '@entities/attributes'
import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AttributesHeader, AttributesSidebar, AttributesTable } from '@widgets/settings'

const AttributesPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.ATTRIBUTES.title })

  const { groupOptions, typeOptions } = useAttributeFilterOptions()

  return (
    <Layout
      sidebar={<AttributesSidebar />}
      header={
        <AttributesHeader key={groupOptions.length} optionsList={{ groupOptions, typeOptions }} />
      }
    >
      <AttributesTable />
    </Layout>
  )
}

export { AttributesPage }
