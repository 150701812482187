import { ROUTES } from '@shared/config'
import { deleteCookie } from '@shared/lib'

const forbiddenHandler = (response: Response) => {
  if (response.status === 403) {
    // Удаляем куки 'csrftoken' так как 'sessionid' уже удалена сервером и нет доступа
    deleteCookie('csrftoken')

    // Перенаправляем на страницу логина
    window.location.href = ROUTES.LOGIN.path
  }
}

export { forbiddenHandler }
