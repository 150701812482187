import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'
import {
  AttributeFormField,
  useRemoveAttributeValue,
  useUploadAttributeValue,
} from '@entities/attributes'
import { FileCard } from '@entities/files'
import { Accordion } from '@shared/ui/accordion'
import { Fragment } from 'react'

import { type SkuFileAttributeProps } from './types'

const SkuFileAttribute: ReactFc<SkuFileAttributeProps> = ({ attribute, index, isLoading }) => {
  // Загрузка файла
  const { isFileSubmitting, onSuccess } = useUploadAttributeValue()

  // Удаляем файл
  const { remove, isRemoving } = useRemoveAttributeValue(onSuccess)

  const hasFiles = attribute.files

  return (
    <Fragment key={`attribute-${attribute.id}`}>
      <Accordion
        id={attribute.id.toString()}
        buttonContent={attribute.name}
        paddingSize="l"
        initialIsOpen={index === 0}
        isLoading={isLoading}
      >
        {hasFiles && (
          <EuiFlexGroup gutterSize="s" wrap>
            {attribute.files.map((file) => {
              return (
                <FileCard
                  key={file.id}
                  {...file}
                  onRemove={remove(attribute.value_id)}
                  isRemoving={isRemoving}
                />
              )
            })}
          </EuiFlexGroup>
        )}

        {!hasFiles && (
          <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
            <EuiFlexItem grow={3}>
              <AttributeFormField
                attribute={attribute}
                disablePlaceholder
                isSubmitting={isFileSubmitting}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </Accordion>
      <EuiHorizontalRule margin="s" />
    </Fragment>
  )
}

export { SkuFileAttribute }
