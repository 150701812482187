import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    group: css`
      .euiFilterButton-isSelected {
        background-color: ${euiTheme.colors.primary} !important;
        color: #fff;
      }
    `,
  }
}
