// @ts-nocheck TODO: Убрать после того, как бекенд поправит, что data это объект, а не массив
import { $api } from '@shared/api'

const useUserPermissions = () => {
  const { isLoading, data } = $api.useQuery('get', '/user_settings/')

  return {
    isLoading,
    permissions: data?.permissions,
  }
}

export { useUserPermissions }
