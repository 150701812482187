import { EuiSpacer, EuiText, EuiTextTruncate } from '@elastic/eui'
import { MESSAGE } from '@shared/config'
import { fontSemiBold, noTextDecoration } from '@shared/ui/styles'
import { Link } from 'react-router-dom'

import { ProgressWithStatus } from '../progress-with-status'
import { SkuCardImage } from './sku-card-image'
import { SkuCardWrapper } from './sku-card-wrapper'
import { type SkuCardProps } from './types'

export const SkuCard: ReactFc<SkuCardProps> = (props) => {
  const { href, name, progress, status, cid } = props

  return (
    <SkuCardWrapper>
      <SkuCardImage {...props} />
      <EuiSpacer size="l" />

      <Link to={href} css={noTextDecoration}>
        <EuiText css={fontSemiBold} color="default">
          <EuiTextTruncate text={name || MESSAGE.EMPTY_NAME} />
        </EuiText>
      </Link>

      <EuiSpacer size="s" />
      <EuiText>
        <EuiTextTruncate text={cid || MESSAGE.EMPTY_VALUE} />
      </EuiText>

      <EuiSpacer size="s" />
      <ProgressWithStatus progress={progress} status={status} variant="full" />
    </SkuCardWrapper>
  )
}
