import { EuiAccordion, type EuiAccordionProps } from '@elastic/eui'

export const Accordion: ReactFc<React.PropsWithChildren<EuiAccordionProps>> = ({
  children,
  ...props
}) => {
  return (
    <EuiAccordion {...props} arrowDisplay="right">
      {children}
    </EuiAccordion>
  )
}
