import { css } from '@emotion/react'
import { ELEMENTS_S_WIDTH } from '@shared/config'

export const card = css`
  position: relative;
  max-width: ${ELEMENTS_S_WIDTH}px;
`
export const deleteButton = css`
  position: absolute;
  top: 8px;
  right: 8px;
`
