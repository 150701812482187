import { EuiPanel } from '@elastic/eui'
import { useSkuTitle } from '@entities/catalog'
import { SkuPrintDocument } from '@features/catalog'
import { usePrintData } from '@features/catalog/'
import { PDFViewer } from '@react-pdf/renderer'
import { useMeta } from '@shared/hooks'
import { minHViewport } from '@shared/ui/styles'

export const SkuPagePrint: ReactFc = () => {
  const title = useSkuTitle()
  useMeta({ title })

  const attributesGroupsList = usePrintData()

  if (attributesGroupsList) {
    return (
      <EuiPanel paddingSize="none">
        <PDFViewer width="100%" css={minHViewport}>
          <SkuPrintDocument attributesGroupsList={attributesGroupsList} />
        </PDFViewer>
      </EuiPanel>
    )
  }

  return null
}
