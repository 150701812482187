import { ATTRIBUTE_FLAGS, extractAttribute, useObjectGroupApi } from '@shared/api'

import { type AttributeGroupNameProps } from './types'

const AttributeGroupName: ReactFc<AttributeGroupNameProps> = ({ groupId }) => {
  const { data } = useObjectGroupApi(groupId)
  const group = extractAttribute(data, ATTRIBUTE_FLAGS.NAME)

  return <>{group?.value}</>
}

export { AttributeGroupName }
