import { PAGE_TITLES } from '@shared/config'
import { useDocumentTitle } from '@uidotdev/usehooks'

import { type UseMetaArgs } from './types'

/**
 * Хук для установки meta параметров документа.
 *
 * @param { UseMetaArgs } title - Заголовок страницы.
 */
export const useMeta = ({ title }: UseMetaArgs) => {
  // TODO: Решить какие будут заголовки на внутренних страницах
  useDocumentTitle(`${title}${PAGE_TITLES.SUFFIX}`)
}
