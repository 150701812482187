import { type COLORS } from '@elastic/eui'

export const getProgressColor = (progress: number): (typeof COLORS)[number] => {
  switch (true) {
    case progress >= 75:
      return 'success'
    case progress >= 50:
      return 'primary'
    case progress >= 25:
      return 'warning'
    default:
      return 'danger'
  }
}
