import { API_ATTRIBUTE_LIST, buildRequestApi, HTTP_METHOD } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'

const addAttributeApi = buildRequestApi<ApiAttribute, Mutable<ApiAttribute>>(
  API_ATTRIBUTE_LIST,
  'Ошибка при добавлении атрибута',
  {
    method: HTTP_METHOD.POST,
  },
)

export { addAttributeApi }
