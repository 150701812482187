import { EuiButton, EuiForm } from '@elastic/eui'
import { FormError, PasswordInput, TextInput } from '@shared/ui/form'
import { textLeft } from '@shared/ui/styles'
import { FormProvider } from 'react-hook-form'

import { useAuthForm } from '../model'

export const AuthForm = () => {
  const { methods, handleSubmit } = useAuthForm()

  return (
    <FormProvider {...methods}>
      <EuiForm component="form" onSubmit={handleSubmit} css={textLeft}>
        <TextInput
          name="username"
          placeholder="Логин"
          icon="user"
          registerOptions={{ required: 'Введите логин' }}
        />
        <PasswordInput name="password" />

        <EuiButton type="submit" fill fullWidth>
          Войти
        </EuiButton>

        <FormError />
      </EuiForm>
    </FormProvider>
  )
}
