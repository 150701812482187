import { useAttributeApi, useAttributeTitle } from '@entities/attributes'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AttributeInfo } from '@widgets/settings'
import { useParams } from 'react-router-dom'

export const AttributePage: ReactFc = () => {
  const { id: attributeId } = useParams()
  const attribute = useAttributeApi(attributeId!)
  const title = useAttributeTitle(attribute)

  useMeta({ title })

  return (
    <Layout>
      <AttributeInfo />
    </Layout>
  )
}
