import {
  useAttributeGroupsList,
  useRemoveAttributeValue,
  useUpdateSkuAttributes,
} from '@entities/attributes'
import { useSkuApi } from '@entities/catalog'
import { API_ATTRIBUTE_TYPE, API_QUERY_KEY_OPENAPI, queryClient } from '@shared/api'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const useSkuUpdateAttributes = () => {
  const { id: skuIdParam = '' } = useParams()
  const skuId = parseInt(skuIdParam)
  const { data: sku, isLoading: isSkuLoading } = useSkuApi(skuIdParam)
  const { isLoading: isAttributesGroupsLoading } = useAttributeGroupsList()
  const isDataLoading = isSkuLoading || isAttributesGroupsLoading

  const methods = useForm()

  useEffect(() => {
    if (!isDataLoading && sku?.attributes) {
      const defaultValues = sku.attributes.reduce(
        (acc, attribute) => {
          if (attribute.attribute_type !== API_ATTRIBUTE_TYPE.FILE) {
            acc[attribute.id] = attribute.value || ''
          }

          return acc
        },
        {} as Record<number, string>,
      )

      // Обновляем значения формы
      methods.reset(defaultValues)
    }
  }, [sku])

  // Отслеживаем изменения всех полей формы
  const watchedFields = methods.watch()

  const changed = Object.keys(watchedFields).filter((field) => {
    return methods.formState.dirtyFields[field]
  })

  const { handleUpdateAttribute, isSubmitting } = useUpdateSkuAttributes(skuId)

  const updateHandler = () => {
    changed.map((id) => {
      const attributeId = parseInt(id)

      const attribute = sku?.attributes.find(({ id }) => {
        return id === attributeId
      })

      handleUpdateAttribute(attributeId, watchedFields[attributeId], attribute?.value_id)
    })
  }

  // Удаляем файл
  const onSuccess = () => {
    queryClient.refetchQueries({
      queryKey: API_QUERY_KEY_OPENAPI.SKU(skuId),
    })
  }

  const { remove, isRemoving } = useRemoveAttributeValue(onSuccess)

  return {
    methods,
    changed,
    updateHandler,
    remove,
    isRemoving,
    isSubmitting,
  }
}

export { useSkuUpdateAttributes }
