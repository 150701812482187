import { EuiProgress } from '@elastic/eui'

import { getProgressColor } from './get-progress-color'
import { type ProgressProps } from './types'

export const Progress: ReactFc<ProgressProps> = ({ value, label }) => {
  return (
    <EuiProgress
      label={label}
      value={value}
      max={100}
      size="m"
      valueText
      color={getProgressColor(value)}
    />
  )
}
