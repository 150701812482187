import { EuiFlexGroup } from '@elastic/eui'
import { useStyles } from '@shared/ui/styles'

import { usePrintLink, usePublicLink } from '../model'
import { SkuToolbarButton } from './sku-toolbar-button'
import { SkuToolbarDownloadButton } from './sku-toolbar-download-button'
import { getStyles } from './styles'

export const SkuToolbar: ReactFc = () => {
  const styles = useStyles(getStyles)

  const printSkuPage = usePrintLink()

  const { isPublic, openPublicLink } = usePublicLink()

  return (
    <EuiFlexGroup gutterSize="none" css={styles.toolbar}>
      <SkuToolbarDownloadButton content="Скачать описание" iconType="save" />
      <SkuToolbarButton content="Версия для печати" iconType="document" onClick={printSkuPage} />
      <SkuToolbarButton
        content="Оповестить об изменениях"
        iconType="userAvatar"
        onClick={() => {
          return alert('Оповестить об изменениях')
        }}
      />
      <SkuToolbarButton
        content={isPublic ? 'Ссылка в публичном каталоге' : 'Ссылка появится после публикации'}
        iconType="editorLink"
        onClick={openPublicLink}
        isDisabled={!isPublic}
      />
    </EuiFlexGroup>
  )
}
