import { EuiCheckbox, EuiFormRow } from '@elastic/eui'
import { isFieldError } from '@shared/lib'
import { useFormContext } from 'react-hook-form'

import { type CheckboxInputProps } from './types'

const CheckboxInput: ReactFc<CheckboxInputProps> = ({
  name,
  label,
  registerOptions,
  inputProps,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext()

  const error = errors?.[name]

  const { onBlur, onChange, ref } = register(name, registerOptions)

  const value = watch(name)

  return (
    <EuiFormRow label={label} isInvalid={!!error} error={isFieldError(error) && error.message}>
      <EuiCheckbox
        id={name}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        checked={value}
        {...inputProps}
      />
    </EuiFormRow>
  )
}

export { CheckboxInput }
