import { EuiFieldText, EuiFormRow } from '@elastic/eui'
import { isFieldError } from '@shared/lib'
import { useFormContext } from 'react-hook-form'

import { type TextInputProps } from './types'

export const TextInput: ReactFc<TextInputProps> = ({
  name,
  icon,
  placeholder,
  registerOptions,
  inputProps,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { onBlur, onChange, ref } = register(name, registerOptions)
  const error = errors?.[name]

  return (
    <EuiFormRow
      label={placeholder}
      isInvalid={!!error}
      error={isFieldError(error) && error.message}
    >
      <EuiFieldText
        icon={icon}
        placeholder={placeholder}
        isInvalid={!!error}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        {...inputProps}
      />
    </EuiFormRow>
  )
}
