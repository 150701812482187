import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'

export const TaxonomyPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.TAXONOMY.title })

  return (
    <div>
      <h1>Taxonomy</h1>
    </div>
  )
}
