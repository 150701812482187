import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { ELEMENTS_S_WIDTH } from '@shared/config'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    breadcrumbs: css`
      gap: ${euiTheme.size.s};
      .euiBreadcrumb {
        max-width: ${ELEMENTS_S_WIDTH}px;
      }
      .euiBreadcrumb:not(:last-of-type)::after {
        transform: none;
        background-color: transparent;
        width: ${euiTheme.size.base};
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23343741" viewBox="0 0 16 16" class="euiIcon css-1q0tcaq-euiIcon-s" role="img" aria-hidden="true"><path fill-rule="evenodd" d="M4.982 14.043a.75.75 0 0 1-.025-1.06l4.591-4.81a.25.25 0 0 0 0-.346l-4.59-4.81a.75.75 0 0 1 1.085-1.035l4.59 4.81a1.75 1.75 0 0 1 0 2.416l-4.59 4.81a.75.75 0 0 1-1.06.024Z" clip-rule="evenodd"></path></svg>');
        margin-block-start: 1px;
      }
      .euiBreadcrumb .euiLink {
        text-decoration: none !important;
        font-weight: ${euiTheme.font.weight.semiBold};
        color: ${euiTheme.colors.text} !important;
      }
    `,
  }
}
