import { type AddAttributesListArgs } from './types'

export const addAttributesList = ({ groups, attributes }: AddAttributesListArgs) => {
  return groups.map((group) => {
    const detailedAttributes = group.attributesIds
      .map((attrId) => {
        return attributes.find((attr) => {
          return attr.id === attrId
        })
      })
      .filter((attr) => {
        return attr !== undefined
      })

    return {
      ...group,
      attributes: detailedAttributes,
    }
  })
}
