import { fetchWrapper } from '@shared/api'
import { API_LOGIN, HTTP_METHOD } from '@shared/api'

import { type AuthFormInputs, type AuthResponse } from '../types'

export const postLogin = ({ username, password }: AuthFormInputs) => {
  return fetchWrapper<AuthResponse, AuthFormInputs>(API_LOGIN, {
    method: HTTP_METHOD.POST,
    body: { username, password },
  })
}
