import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'

export const RelatedProductsPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.RELATED_PRODUCTS.title })

  return (
    <div>
      <h1>Related Products</h1>
    </div>
  )
}
