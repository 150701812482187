import { useSkuTitle } from '@entities/catalog'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { SkuSidebar, SkuTabs } from '@widgets/catalog'

export const SkuPage: ReactFc = () => {
  const title = useSkuTitle()
  useMeta({ title })

  return (
    <Layout
      sidebar={<SkuSidebar />}
      pageBodyProps={{
        paddingSize: 'm',
      }}
    >
      <SkuTabs />
    </Layout>
  )
}
