import { useCategoryListApi } from '@entities/catalog'

import { convertCategoryToOption } from '../lib'

const useCategoryOptions = () => {
  const { data: categories = [], isLoading } = useCategoryListApi()

  const categoryOptions = categories.map(convertCategoryToOption)

  categoryOptions.unshift({ value: 0, inputDisplay: 'Корневая категория' })

  return { categoryOptions, isLoading }
}

export { useCategoryOptions }
