import { EuiFlexGroup, EuiPanel, EuiSpacer, useEuiPaddingCSS } from '@elastic/eui'
import { SIMPLE_LAYOUT_LOGO_WIDTH } from '@shared/config'
import { Logo } from '@shared/ui/icons'
import { minHViewport, textCenter } from '@shared/ui/styles'

import { content, group, panel } from './styles'

export const SimpleLayout: ReactFc<React.PropsWithChildren> = ({ children }) => {
  const paddingStyles = useEuiPaddingCSS()

  return (
    <EuiFlexGroup
      justifyContent="center"
      alignItems="center"
      css={[group, minHViewport, paddingStyles['xl']]}
    >
      <EuiPanel grow={false} paddingSize="m" css={[panel, textCenter]}>
        <EuiPanel hasShadow={false} paddingSize="none" css={content}>
          <EuiSpacer size="xxl" />
          <Logo size={SIMPLE_LAYOUT_LOGO_WIDTH} variant="full" />
          <EuiSpacer size="xxl" />
          {children}
        </EuiPanel>
      </EuiPanel>
    </EuiFlexGroup>
  )
}
