import {
  type QueryFunction,
  type QueryKey,
  useQuery as useQueryTanstack,
} from '@tanstack/react-query'

export const useQuery = <T>(
  queryKey: QueryKey,
  queryFn: QueryFunction<T>,
): ReturnType<typeof useQueryTanstack<T>> => {
  return useQueryTanstack<T>({
    queryKey: queryKey,
    queryFn: queryFn,
  })
}
