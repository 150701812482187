import { useToggle as useUidotdevToggle } from '@uidotdev/usehooks'

import { type UseToggleReturn } from './types'

export const useToggle = (isTrueExternal = false): UseToggleReturn => {
  const [isTrue, toggleIsTrue] = useUidotdevToggle(isTrueExternal)

  const handleToggle = () => {
    toggleIsTrue()
  }

  return [isTrue, handleToggle, toggleIsTrue]
}
