import { EuiButton, EuiPopover, EuiPopoverFooter, EuiSelectable } from '@elastic/eui'
import { ELEMENTS_XS_WIDTH } from '@shared/config'
import { useToggle } from '@shared/hooks'

import { type SelectablePopoverProps } from './type'

const SelectablePopover = <T = unknown,>({
  label,
  options,
  onChange,
  onResetClick,
  ...props
}: SelectablePopoverProps<T>) => {
  const [isMenuOpen, toggleMenu] = useToggle()

  return (
    <EuiPopover
      panelPaddingSize="none"
      button={
        <EuiButton iconType="arrowDown" iconSide="right" size="s" onClick={toggleMenu}>
          {label}
        </EuiButton>
      }
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
    >
      <EuiSelectable options={options} onChange={onChange} {...props}>
        {(list) => {
          return (
            <div style={{ width: ELEMENTS_XS_WIDTH }}>
              {list}

              <EuiPopoverFooter paddingSize="s">
                <EuiButton size="s" fullWidth onClick={onResetClick}>
                  Сбросить
                </EuiButton>
              </EuiPopoverFooter>
            </div>
          )
        }}
      </EuiSelectable>
    </EuiPopover>
  )
}

export { SelectablePopover }
