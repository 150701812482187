import { useCategoryListApi } from '@entities/catalog'
import { useRouterParams } from '@shared/hooks'
import { useMemo } from 'react'

import { createCategoryFilterTree } from './create-category-filter-tree'
import { createSearchFilterTree } from './create-search-filter-tree'
import { extractCategoryData } from './extract-category-data'

export const useCategoryFilter = () => {
  const { getParamId, getParamValues, hasParam } = useRouterParams()
  const { data: categories = [] } = useCategoryListApi()

  const catalogId = getParamId()

  // Делаем мемонизацию для предотвращения лишних пересчётов дерева
  const catalogCategories = useMemo(() => {
    if (catalogId) {
      return extractCategoryData(categories).filter((category) => {
        return category.object_group.includes(catalogId)
      })
    }

    return extractCategoryData(categories)
  }, [catalogId, categories])

  let treeItems = createCategoryFilterTree(catalogCategories)

  let search = ''

  if (hasParam('searchCategory')) {
    search = getParamValues('searchCategory').toString().toLowerCase()
    treeItems = createCategoryFilterTree(createSearchFilterTree(catalogCategories, search))
  }

  return {
    treeItems,
    search,
  }
}
