import { EuiSpacer, EuiText } from '@elastic/eui'
import { ErrorMessage } from '@hookform/error-message'
import { useFormContext } from 'react-hook-form'

export const FormError = () => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <EuiSpacer size="xs" />
      <ErrorMessage
        errors={errors}
        name="root"
        render={({ message }) => {
          return (
            <EuiText size="s" color="danger">
              {message}
            </EuiText>
          )
        }}
      />
    </>
  )
}
