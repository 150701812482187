import { EuiLink, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'
import { AuthForm } from '@features/auth'
import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { SimpleLayout } from '@shared/ui/layouts'
import { Layout } from '@shared/ui/layouts'

const LoginPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.LOGIN.title })

  return (
    <Layout>
      <SimpleLayout>
        <EuiTitle size="xs">
          <EuiText>Вход в систему</EuiText>
        </EuiTitle>
        <EuiSpacer size="m" />
        <EuiText size="s" color="subdued">
          Авторизуйтесь, чтобы продолжить
        </EuiText>
        <EuiSpacer size="s" />

        <AuthForm />
        <EuiSpacer size="s" />

        <EuiText size="xs">
          <EuiLink href="#">Забыли пароль?</EuiLink>
        </EuiText>
        <EuiSpacer size="m" />

        <EuiText size="xs">
          <EuiLink href="#">Использовать другой метод авторизации</EuiLink>
        </EuiText>
        <EuiSpacer size="s" />
      </SimpleLayout>
    </Layout>
  )
}

export { LoginPage }
