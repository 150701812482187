import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    toolbar: css`
      border-radius: ${euiTheme.size.xs};
      overflow: hidden;
      & > .euiToolTipAnchor:not(:last-child),
      & > a:not(:last-child) {
        border-right: 1px solid ${euiTheme.colors.lightShade};
      }
    `,
    button: css`
      border-radius: 0;
      inline-size: ${euiTheme.size.xl};
    `,
  }
}
