import { EuiForm } from '@elastic/eui'
import { useUpdateAttributes } from '@entities/attributes'
import { useCategoryOptions } from '@entities/catalog'
import { type ApiAttribute } from '@shared/api/types'
import { BUTTON, MESSAGE, PLACEHOLDER } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { FormError, SelectInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { type LinkAttributeToCategoryOnSubmitArg, type LinkAttributeToCategoryProps } from './types'

const LinkAttributeToCategory: ReactFc<LinkAttributeToCategoryProps> = ({ isOpen, onClose }) => {
  const methods = useForm<ApiAttribute>()
  const { getParamValues } = useRouterParams()

  const attributeIds = getParamValues('selected')

  const { categoryOptions, isLoading: isCategoryOptionsLoading } = useCategoryOptions()

  const {
    mutation: { mutateAsync },
    isSubmitting,
  } = useUpdateAttributes({ attributeIds, methods })

  const onSubmit = async (data: LinkAttributeToCategoryOnSubmitArg) => {
    await mutateAsync(data.category ? data : { category: null })

    methods.reset()
    onClose()
  }

  return (
    <FormProvider {...methods}>
      <Modal
        title={MESSAGE.NEW_ATTRIBUTE}
        submitButtonText={BUTTON.ATTRIBUTES_LINK}
        onClose={onClose}
        onSubmit={methods.handleSubmit(onSubmit)}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <Controller
            name="category"
            control={methods.control}
            rules={{ required: MESSAGE.CATEGORY_REQUIRED }}
            render={() => {
              return (
                <SelectInput
                  name="category"
                  placeholder={PLACEHOLDER.ATTRIBUTES_CATEGORY_SELECT}
                  label={BUTTON.ATTRIBUTES_CATEGORY_SELECT}
                  inputProps={{
                    options: categoryOptions,
                    isLoading: isCategoryOptionsLoading,
                  }}
                />
              )
            }}
          />
          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { LinkAttributeToCategory }
