import { EuiContextMenuItem, EuiIcon } from '@elastic/eui'
import { BUTTON } from '@shared/config'

import { useRemoveAttributeGroups } from '../model'
import { type RemoveAttributeGroupProps } from './types'

const RemoveAttributeGroup: ReactFc<RemoveAttributeGroupProps> = ({ id }) => {
  const { removeFromGroups } = useRemoveAttributeGroups([id])

  return (
    <EuiContextMenuItem
      icon={<EuiIcon type="trash" color="danger" />}
      size="s"
      onClick={removeFromGroups}
    >
      {BUTTON.DELETE}
    </EuiContextMenuItem>
  )
}

export { RemoveAttributeGroup }
