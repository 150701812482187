import { EuiForm, EuiPanel, EuiSpacer } from '@elastic/eui'
import { CategoryFilter, CategorySearch } from '@features/catalog'
import React from 'react'

const AttributesSidebarForm: ReactFc = () => {
  return (
    <EuiPanel hasShadow={false}>
      <EuiForm fullWidth>
        <CategorySearch />
        <EuiSpacer />

        {/* TODO: разделить фильтры категорий, сделать свой для категорий катлога и свой для категорий атрибутов */}
        <CategoryFilter enableRoot isAttributesCategory />
      </EuiForm>
    </EuiPanel>
  )
}

const AttributesSidebarFormMemo = React.memo(AttributesSidebarForm) as typeof AttributesSidebarForm

export { AttributesSidebarFormMemo as AttributesSidebarForm }
