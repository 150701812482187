import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { AttributeFormField } from '@entities/attributes'
import { FileCard } from '@entities/files'
import { type ApiObjectAttribute } from '@shared/api/types'

import { useSkuUpdateAttributes } from '../model'

const SkuAttribute: ReactFc<ApiObjectAttribute> = (attribute) => {
  // Сохранение
  const { remove, isSubmitting, isRemoving } = useSkuUpdateAttributes()

  const isFile = attribute.files

  return (
    <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
      <EuiFlexItem grow={3}>{attribute.name}</EuiFlexItem>

      <EuiFlexItem grow={7}>
        {isFile && (
          <EuiFlexGroup gutterSize="s" wrap>
            {attribute.files.map((file) => {
              return (
                <FileCard
                  key={file.id}
                  {...file}
                  onRemove={remove(attribute.value_id)}
                  isRemoving={isRemoving}
                />
              )
            })}
          </EuiFlexGroup>
        )}

        {!isFile && (
          <AttributeFormField
            attribute={attribute}
            disablePlaceholder
            isCompressed
            isSubmitting={isSubmitting}
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export { SkuAttribute }
