import { type ApiAttribute } from '@shared/api/types'

import { attributeTypeMap } from './attribute-type-map'

export const renderAttributeType: RenderColumn<ApiAttribute, 'attribute_type'> = (type) => {
  if (!type) {
    return null
  }

  return <>{attributeTypeMap[type]}</>
}
