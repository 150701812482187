import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import { type ApiObjectWithAttributes } from '@shared/api/types'
import { MESSAGE, PAGE_TITLES } from '@shared/config'
import { type UseQueryResult } from '@tanstack/react-query'

export const useTitle = (item: UseQueryResult<ApiObjectWithAttributes>) => {
  const { isSuccess, data } = item

  if (isSuccess) {
    const name = extractAttribute(data, ATTRIBUTE_FLAGS.NAME)?.value

    if (name) {
      return name
    }

    return MESSAGE.EMPTY_NAME
  }

  return PAGE_TITLES.LOADING
}
