import { EuiButtonIcon, EuiHeaderSectionItem } from '@elastic/eui'
import { useUserSettingsStore } from '@entities/user'

export const CatalogSidebarToggle: ReactFc = () => {
  const isCatalogSidebarOpen = useUserSettingsStore.use.isCatalogSidebarOpen()
  const toggleCatalogSidebarOpen = useUserSettingsStore.use.toggleCatalogSidebarOpen()

  const display = isCatalogSidebarOpen ? 'fill' : 'base'

  const label = isCatalogSidebarOpen
    ? 'Скрыть навигацию по каталогам'
    : 'Открыть навигацию по каталогам'

  return (
    <EuiHeaderSectionItem>
      <EuiButtonIcon
        aria-label={label}
        display={display}
        iconType="apmTrace"
        size="s"
        onClick={toggleCatalogSidebarOpen}
      />
    </EuiHeaderSectionItem>
  )
}
