import { $api } from '@shared/api'

export const useSkuApi = (id: string = '') => {
  return $api.useQuery('get', '/skus/{id}/', {
    params: {
      path: {
        id: parseInt(id),
      },
    },
  })
}
