import { CATALOG_VIEW } from '@shared/config'
import { createStoreSelectors } from '@shared/lib'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { type UserSettingsProps, type UserSettingsStore } from './types'

const initialValues: UserSettingsProps = {
  isSidebarOpen: true,
  isCatalogSidebarOpen: false,
  catalogView: CATALOG_VIEW.TABLE,
  isSkuSidebarOpen: true,
  attributeOrder: [],
}

const useUserSettingsStoreBase = create<UserSettingsStore>()(
  persist(
    (set, get) => {
      return {
        ...initialValues,

        toggleSidebarOpen: () => {
          return set({ isSidebarOpen: !get().isSidebarOpen })
        },

        toggleCatalogSidebarOpen: () => {
          return set({ isCatalogSidebarOpen: !get().isCatalogSidebarOpen })
        },

        toggleCatalogView: (view) => {
          return set({ catalogView: view })
        },

        toggleSkuSidebarOpen: () => {
          return set({ isSkuSidebarOpen: !get().isSkuSidebarOpen })
        },

        setAttributeOrder: (order) => {
          return set({ attributeOrder: order })
        },

        reset: () => {
          return set(initialValues)
        },
      }
    },
    {
      name: 'user-settings',
      storage: createJSONStorage(() => {
        return localStorage
      }),
    },
  ),
)

export const useUserSettingsStore = createStoreSelectors(useUserSettingsStoreBase)
