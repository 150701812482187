import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AttributeGroupsHeader, AttributeGroupsTable } from '@widgets/settings'

export const AttributeGroupsPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.ATTRIBUTE_GROUPS.title })

  return (
    <Layout header={<AttributeGroupsHeader />}>
      <AttributeGroupsTable />
    </Layout>
  )
}
