import { attributeTypeMap } from './attribute-type-map'

// TODO: опции потом должны будут приходить с бэка
const useAttributeTypeOptions = () => {
  const typeOptions = Object.entries(attributeTypeMap).map(([key, value]) => {
    return {
      value: parseInt(key),
      inputDisplay: value,
    }
  })

  const isLoading = false

  return { typeOptions, isLoading }
}

export { useAttributeTypeOptions }
