import { $api } from '@shared/api'

const useRemoveAttributeValue = (onSuccessCallback?: EmptyCallback) => {
  const removeAttributeValue = $api.useMutation('delete', '/attribute_values/{id}/')

  const remove = (id: number) => {
    return () => {
      removeAttributeValue.mutate(
        {
          params: {
            path: {
              id,
            },
          },
        },
        {
          onSuccess: () => {
            if (onSuccessCallback) {
              onSuccessCallback()
            }
          },
        },
      )
    }
  }

  return {
    removeAttributeValue,
    remove,
    isRemoving: removeAttributeValue.isPending,
  }
}

export { useRemoveAttributeValue }
