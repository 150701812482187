import { ROUTES } from '@shared/config'
import { Navigate, useMatches } from 'react-router-dom'

import { useUserPermissions } from '../model'
import { type PermissionsMatches } from './types'

const PermissionProvider: ReactFc = ({ children }) => {
  const { isLoading, permissions } = useUserPermissions()
  const matches = useMatches() as PermissionsMatches

  if (isLoading) {
    return null
  }

  const hasPermission = matches.every((match) => {
    if (match?.handle?.permission) {
      return permissions[match.handle.permission]
    }

    return true // Если маршрут не требует прав, пропускаем его
  })

  // Если хотя бы один маршрут требует права, которых у пользователя нет, перенаправляем на страницу ошибки
  if (!hasPermission) {
    return <Navigate to={ROUTES.PERMISSION_ERROR.path} />
  }

  return <>{children}</>
}

export { PermissionProvider }
