import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiListGroupItem } from '@elastic/eui'
import { AttributeFormField } from '@entities/attributes'
import { FileCard, FileIcon } from '@entities/files'
import { BUTTON, MESSAGE } from '@shared/config'
import { FormError } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { textRight } from '@shared/ui/styles'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'

import { useUpdateAttribute } from '../model'
import { type SkuAttributesItemProps } from './types'

const SkuAttributesListItem: ReactFc<SkuAttributesItemProps> = (attribute) => {
  const {
    isOpen,
    showModal,
    closeModal,
    methods,
    updateAttribute,
    remove,
    isRemoving,
    isSubmitting,
  } = useUpdateAttribute()

  useEffect(() => {
    methods.reset({
      [attribute.id]: attribute.value || '',
      attributeValueId: (attribute.value_id || '').toString(),
    })
  }, [attribute])

  return (
    <EuiListGroupItem
      key={attribute.id}
      size="s"
      wrapText
      label={
        <EuiFlexGroup gutterSize="s" justifyContent="spaceBetween">
          <EuiFlexItem>{attribute.name}</EuiFlexItem>

          {/* TODO: Решить, нужно ли выводить несколько иконок или достаточной одной первой */}
          {(attribute.files && <FileIcon {...attribute.files[0]} />) || (
            <EuiFlexItem css={textRight}>{attribute.value || '–'}</EuiFlexItem>
          )}

          <FormProvider {...methods}>
            <Modal
              key={attribute.id}
              title={MESSAGE.EDIT_ATTRIBUTE}
              onClose={closeModal}
              submitButtonText={BUTTON.SAVE}
              onSubmit={updateAttribute}
              isOpen={isOpen}
              isSubmitting={isSubmitting}
            >
              <EuiForm component="form" onSubmit={updateAttribute}>
                {(attribute.files &&
                  attribute.files.map((file) => {
                    return (
                      <FileCard
                        key={file.id}
                        {...file}
                        onRemove={remove(attribute.value_id)}
                        isRemoving={isRemoving}
                      />
                    )
                  })) || <AttributeFormField attribute={attribute} isSubmitting={isSubmitting} />}

                <FormError />
              </EuiForm>
            </Modal>
          </FormProvider>
        </EuiFlexGroup>
      }
      extraAction={{
        color: 'primary',
        iconType: 'pencil',
        alwaysShow: true,
        'aria-label': BUTTON.EDIT,
        onClick: showModal,
        disabled: !attribute.is_editable,
      }}
    />
  )
}

export { SkuAttributesListItem }
