import { EuiCard } from '@elastic/eui'

import { card } from './styles'

export const SkuCardWrapper: ReactFc<React.PropsWithChildren> = ({ children }) => {
  return (
    <EuiCard
      title={false}
      titleSize="xs"
      paddingSize="s"
      textAlign="left"
      display="plain"
      hasBorder
      css={card}
    >
      {children}
    </EuiCard>
  )
}
