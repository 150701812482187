import { EuiHorizontalRule, EuiSkeletonRectangle } from '@elastic/eui'

const SkuTabSkeleton: ReactFc = () => {
  return (
    <>
      <EuiSkeletonRectangle width="100%" height={24} />
      <EuiHorizontalRule margin="s" />
    </>
  )
}

export { SkuTabSkeleton }
