import { EuiBadge, EuiFlexGroup, EuiIcon, transparentize, useEuiTheme } from '@elastic/eui'
import { ATTRIBUTE_LABELS, ROUTES } from '@shared/config'
import { Link } from 'react-router-dom'

import { type AttributeNameProps } from './types'

const AttributeName: ReactFc<AttributeNameProps> = ({ name, label: labelExternal, id }) => {
  const { euiTheme } = useEuiTheme()

  const renderLabel = (label: string) => {
    if (label === ATTRIBUTE_LABELS.PREVIEW) {
      return <EuiIcon type="image" size="s" />
    }

    if (label) {
      return <EuiBadge color={transparentize(euiTheme.colors.warning, 0.3)}>{label}</EuiBadge>
    }

    return null
  }

  return (
    <EuiFlexGroup alignItems="center" gutterSize="s">
      <Link to={ROUTES.SETTINGS.ATTRIBUTES.ATTRIBUTE.buildPath({ id })}>{name}</Link>

      {renderLabel(labelExternal)}
    </EuiFlexGroup>
  )
}

export { AttributeName }
