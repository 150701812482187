import { type ApiAttribute } from '@shared/api/types'
import { MESSAGE, PAGE_TITLES } from '@shared/config'
import { type UseQueryResult } from '@tanstack/react-query'

const useAttributeTitle = (item: UseQueryResult<ApiAttribute>) => {
  const { isSuccess, data } = item

  if (isSuccess) {
    const name = data?.name

    if (name) {
      return name
    }

    return MESSAGE.EMPTY_NAME
  }

  return PAGE_TITLES.LOADING
}

export { useAttributeTitle }
