import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    header: css`
      background-color: ${euiTheme.colors.body};
    `,
    headerSection: css`
      gap: ${euiTheme.size.m};
    `,
  }
}
