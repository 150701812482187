import { convertSelectToPopoverOptions } from '@shared/lib'

import { useAttributeGroupOptions } from './use-attribute-group-options'
import { useAttributeTypeOptions } from './use-attribute-type-options'

const useAttributeFilterOptions = () => {
  const { typeOptions } = useAttributeTypeOptions()
  const { groupOptions } = useAttributeGroupOptions()

  const filterTypeOptions = convertSelectToPopoverOptions(typeOptions)
  const filterGroupOptions = convertSelectToPopoverOptions(groupOptions)

  return { typeOptions: filterTypeOptions, groupOptions: filterGroupOptions }
}

export { useAttributeFilterOptions }
