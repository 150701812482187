import { $api } from '@shared/api'

export const useObjectGroupApi = (id: number) => {
  return $api.useQuery('get', '/object_groups/{id}/', {
    params: {
      path: {
        id,
      },
    },
  })
}
