import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = () => {
  return {
    item: css`
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      font-size: 1rem;
    `,
    text: css`
      width: 100%;
      text-align: left;
    `,
  }
}
