import { EuiImage } from '@elastic/eui'
import { APP_NAME } from '@shared/config'

import logo from './favicon-light-mode.svg'
import logoFull from './logo-light-mode.svg'
import { type LogoProps } from './types'

export const Logo: ReactFc<LogoProps> = ({ size, variant }) => {
  const logoSrc = variant === 'full' ? logoFull : logo

  return <EuiImage src={logoSrc} size={size} alt={APP_NAME} />
}
