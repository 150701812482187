import { EuiFlexGroup, EuiFlexItem, EuiListGroupItem } from '@elastic/eui'
import { Progress } from '@shared/ui/progress'
import { textRight } from '@shared/ui/styles'

import { type SkuAttributesItemProps } from './types'

const SkuAttributesProgressItem: ReactFc<SkuAttributesItemProps> = (attribute) => {
  return (
    <EuiListGroupItem
      key={attribute.id}
      size="s"
      wrapText
      label={
        <EuiFlexGroup gutterSize="s" justifyContent="spaceBetween">
          <EuiFlexItem>{attribute.name}</EuiFlexItem>
          <EuiFlexItem css={textRight}>
            <Progress value={Number(attribute.value)} />
          </EuiFlexItem>
        </EuiFlexGroup>
      }
    />
  )
}

export { SkuAttributesProgressItem }
