import { css } from '@emotion/react'
import { ELEMENTS_M_WIDTH, SIMPLE_LAYOUT_CONTENT_WIDTH } from '@shared/config'
import { mxAuto } from '@shared/ui/styles'

import bg from './background.gif'

export const group = css`
  background-image: url(${bg}) !important;
`

export const panel = css`
  width: ${ELEMENTS_M_WIDTH}px;
`

export const content = css`
  max-width: ${SIMPLE_LAYOUT_CONTENT_WIDTH}px;
  ${mxAuto}
`
