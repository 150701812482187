export const isNotNull = <T>(value: T | null): value is T => {
  return value !== null
}

export const createNewObject = () => {
  return Object.create(null)
}

export const getLocalDate = (date: string | undefined) => {
  return date ? new Date(date).toLocaleString() : null
}

export const fileSizeFormatter = (fileSize: NullableNumber) => {
  if (!fileSize) {
    return null
  }

  if (fileSize < 1024) {
    return fileSize + ' B'
  }

  const unitIndex = Math.floor(Math.log(fileSize) / Math.log(1024))
  const num = fileSize / Math.pow(1024, unitIndex)
  const round = Math.round(num)

  const result = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round

  return `${result} ${'KMGTPEZY'[unitIndex - 1]}B`
}
