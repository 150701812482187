import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonRectangle,
  EuiSkeletonText,
  EuiSpacer,
} from '@elastic/eui'
import { CATALOG_CARD_IMAGE_SIZE } from '@shared/config'

import { SkuCardWrapper } from './sku-card-wrapper'

export const SkuCardSkeleton: ReactFc = () => {
  return (
    <SkuCardWrapper>
      <EuiFlexGroup justifyContent="spaceAround">
        <EuiFlexItem grow={false}>
          <EuiSkeletonRectangle width={CATALOG_CARD_IMAGE_SIZE} height={CATALOG_CARD_IMAGE_SIZE} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="l" />

      <EuiSkeletonText lines={4} />
    </SkuCardWrapper>
  )
}
