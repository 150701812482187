import { type ConvertSelectToPopoverOptions } from './types'

const convertSelectToPopoverOptions: ConvertSelectToPopoverOptions = (options) => {
  return options.map(({ value, inputDisplay }) => {
    return {
      label: inputDisplay,
      data: {
        id: value.toString(),
      },
    }
  })
}

export { convertSelectToPopoverOptions }
