import { slugify } from '@elastic/eui'

import { RenderLink } from '../ui'

export const createItem = (name: string, href: string, hasPermisions = false) => {
  return {
    id: slugify(href),
    name,
    href: href,
    renderItem: hasPermisions ? RenderLink : undefined,
    disabled: !hasPermisions,
  }
}
