import { keys } from '@elastic/eui'
import { ELEMENTS_S_WIDTH } from '@shared/config'
import { useCallback, useRef, useState } from 'react'

export const useResizableButton = () => {
  const [width, setWidth] = useState(ELEMENTS_S_WIDTH)
  const initialwidth = useRef(width)
  const initialMouseX = useRef(0)

  const getMouseOrTouchX = (
    event: TouchEvent | MouseEvent | React.MouseEvent | React.TouchEvent,
  ): number => {
    // Some Typescript fooling is needed here
    const xSize = (event as TouchEvent).targetTouches
      ? (event as TouchEvent).targetTouches[0].pageX
      : (event as MouseEvent).pageX

    return xSize
  }

  const onMouseMove = useCallback((event: MouseEvent | TouchEvent) => {
    const mouseOffset = getMouseOrTouchX(event) - initialMouseX.current
    const changedwidth = initialwidth.current + mouseOffset

    setWidth(Math.max(changedwidth, ELEMENTS_S_WIDTH))
  }, [])

  const onMouseUp = useCallback(() => {
    initialMouseX.current = 0

    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
    window.removeEventListener('touchmove', onMouseMove)
    window.removeEventListener('touchend', onMouseUp)
  }, [onMouseMove])

  const onMouseDown = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      initialMouseX.current = getMouseOrTouchX(event)
      initialwidth.current = width

      // Window event listeners instead of React events are used
      // in case the user's mouse leaves the component
      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
      window.addEventListener('touchmove', onMouseMove)
      window.addEventListener('touchend', onMouseUp)
    },
    [width, onMouseMove, onMouseUp],
  )

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    const KEYBOARD_OFFSET = 10

    switch (event.key) {
      case keys.ARROW_RIGHT:
        event.preventDefault() // Safari+VO will screen reader navigate off the button otherwise

        setWidth((width) => {
          return width + KEYBOARD_OFFSET
        })

        break
      case keys.ARROW_LEFT:
        event.preventDefault() // Safari+VO will screen reader navigate off the button otherwise

        setWidth((width) => {
          return Math.max(width - KEYBOARD_OFFSET, ELEMENTS_S_WIDTH)
        })
    }
  }, [])

  return {
    width,
    onMouseDown,
    onKeyDown,
  }
}
