import { type EuiImageProps, type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    group: css`
      width: 100%;
      height: 100%;
      background-color: ${euiTheme.colors.lightestShade};
    `,
    item: css`
      width: 40%;
    `,
    icon: css`
      width: 100%;
      height: 100%;
      fill: ${euiTheme.colors.lightShade};
    `,
  }
}

export const noImagePanelStyle = (
  width: EuiImageProps['width'],
  height: EuiImageProps['height'],
) => {
  const suffixWidth = typeof width === 'number' && 'px'
  const suffixHeight = typeof height === 'number' && 'px'

  const style = css`
    width: ${width}${suffixWidth};
    height: ${height}${suffixHeight};
  `

  return style
}
