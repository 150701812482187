import { EuiBasicTable, type EuiBasicTableProps } from '@elastic/eui'
import { MESSAGE } from '@shared/config'

export const Table = <T extends object>(props: EuiBasicTableProps<T>) => {
  return (
    <EuiBasicTable
      itemId="id"
      noItemsMessage={props.loading ? <>{MESSAGE.LOADING}</> : <>{MESSAGE.EMPTY}</>}
      {...props}
    />
  )
}
