import { EuiCheckbox, EuiTextTruncate } from '@elastic/eui'
import { textLeft } from '@shared/ui/styles'

import { useCategoryFilterCheckbox } from '../model'
import { type CategoryFilterCheckboxProps } from '../types'

export const CategoryFilterCheckbox: ReactFc<CategoryFilterCheckboxProps> = ({
  value,
  label,
  skus_count,
  childNodes,
}) => {
  const { isIndeterminate, isChecked, handleToggle } = useCategoryFilterCheckbox(value, childNodes)

  return (
    <EuiCheckbox
      id={label}
      label={
        <EuiTextTruncate
          text={`${label} (${skus_count})`}
          ellipsis={`… (${skus_count})`}
          css={textLeft}
        />
      }
      checked={isChecked}
      indeterminate={isIndeterminate()}
      onChange={handleToggle}
    />
  )
}
