import { EuiText, EuiTextTruncate } from '@elastic/eui'
import { useRouterParams } from '@shared/hooks'
import { useStyles } from '@shared/ui/styles'

import { type CategoryFilterItemProps } from '../../types'
import { getStyles } from './styles'

export const CategoryFilterItem: ReactFc<CategoryFilterItemProps> = ({
  name,
  items_count,
  value,
  isRoot = false,
  isAttributesCategory = false,
}) => {
  const styles = useStyles(getStyles)
  const { addParamValue, deleteParam, setParam } = useRouterParams()

  const handleClick = () => {
    if (isRoot) {
      deleteParam('category')

      return
    }

    if (isAttributesCategory) {
      setParam('category', value)
    } else {
      addParamValue('category', value)
    }
  }

  return (
    <EuiText css={styles.item} onClick={handleClick}>
      <EuiTextTruncate
        text={`${name} (${items_count})`}
        ellipsis={`… (${items_count})`}
        css={styles.text}
      />
    </EuiText>
  )
}
