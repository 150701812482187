import { useSkuTitle } from '@entities/catalog'
import { usePDF } from '@react-pdf/renderer'
import { useEffect } from 'react'

import { usePrintData } from '../model'
import { SkuPrintDocument } from './sku-print-document'
import { SkuToolbarButton } from './sku-toolbar-button'
import { type SkuToolbarButtonProps } from './types'

export const SkuToolbarDownloadButton: ReactFc<SkuToolbarButtonProps> = (props) => {
  const [instance, updateInstance] = usePDF()
  const title = useSkuTitle()

  const attributesGroupsList = usePrintData()

  useEffect(() => {
    if (attributesGroupsList) {
      updateInstance(<SkuPrintDocument attributesGroupsList={attributesGroupsList} />)
    }
  }, [attributesGroupsList])

  if (attributesGroupsList && instance.url) {
    return (
      <a href={instance.url} download={`${title}.pdf`}>
        <SkuToolbarButton {...props} />
      </a>
    )
  }

  return <SkuToolbarButton {...props} isDisabled />
}
