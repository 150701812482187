import { PAGE_TITLES } from '@shared/config'
import { useEffect, useState } from 'react'

export const usePageTitle = () => {
  const [title, setTitle] = useState(document.title)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const handleTitleChange = () => {
      const pageTitle = document.title.replace(PAGE_TITLES.SUFFIX, '')
      setTitle(pageTitle)
      setIsLoading(pageTitle === PAGE_TITLES.LOADING)
    }

    // Создаем наблюдатель за изменением заголовка
    const observer = new MutationObserver(handleTitleChange)
    observer.observe(document.querySelector('title')!, { childList: true })

    // Обновляем состояние при изменении заголовка
    handleTitleChange()

    return () => {
      observer.disconnect()
    }
  }, [])

  return { title, isLoading }
}
