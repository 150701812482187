import { useSkuListApi } from '@entities/catalog'
import { isCatalogView } from '@features/catalog'
import { CATALOG_VIEW } from '@shared/config'
import { useRouterParams } from '@shared/hooks'

import { CatalogCards } from './catalog-cards'
import { CatalogTable } from './catalog-table'

export const Catalog: ReactFc = () => {
  const { params: query } = useRouterParams()
  const { isLoading, data: skuList = [] } = useSkuListApi(query)

  // TODO: заменить skuList на filteredSkuList когда логики каталогов и категорий будет готова
  // const filteredSkuList = useFilterSkuList(skuList)

  if (isCatalogView(CATALOG_VIEW.CARDS)) {
    return <CatalogCards skuList={skuList} isLoading={isLoading} />
  }

  return <CatalogTable skuList={skuList} isLoading={isLoading} />
}
