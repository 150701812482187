import {
  useAddAttributeValue,
  useRemoveAttributeValue,
  useUpdateAttributeValue,
} from '@entities/attributes'
import { API_QUERY_KEY_OPENAPI, queryClient } from '@shared/api'
import { type ApiAttributeValue } from '@shared/api/types'

const useUpdateSkuAttributes = (skuId: number, onSuccessCallback?: () => void) => {
  const addAttributeValue = useAddAttributeValue()
  const { removeAttributeValue } = useRemoveAttributeValue()
  const updateAttributeValue = useUpdateAttributeValue()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY_OPENAPI.SKU(skuId) })

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const handleUpdateAttribute = (
    attributeId: number,
    attributeValue: string,
    attributeValueId?: number,
  ) => {
    // Если значение атрибута пустое, удаляем его
    if (!attributeValue && attributeValueId) {
      return removeAttributeValue.mutate(
        {
          params: {
            path: {
              id: attributeValueId,
            },
          },
        },
        { onSuccess },
      )
    }

    // Если у атрибута нет значения, создаём новое
    if (!attributeValueId) {
      return addAttributeValue.mutate(
        {
          body: {
            object_id: skuId,
            attribute: attributeId,
            value: attributeValue,
          } as ApiAttributeValue, // TODO: Сделать тип создания аналогично PatchedAttributeValue
        },
        { onSuccess },
      )
    }

    // Обновляем существующее значение
    return updateAttributeValue.mutate(
      {
        params: {
          path: {
            id: attributeValueId,
          },
        },
        body: {
          value: attributeValue,
        },
      },
      { onSuccess },
    )
  }

  const isSubmitting =
    addAttributeValue.isPending || removeAttributeValue.isPending || updateAttributeValue.isPending

  return {
    handleUpdateAttribute,
    isSubmitting,
  }
}

export { useUpdateSkuAttributes }
