import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { CATALOG_TABLE_IMAGE_SIZE } from '@shared/config'
import { Image } from '@shared/ui/image'

import { type Sku } from '../types'

export const renderImage: RenderColumn<Sku, 'preview'> = (_value, { preview, name }) => {
  return (
    <EuiFlexGroup justifyContent="spaceAround">
      <EuiFlexItem grow={false}>
        <Image
          src={preview}
          width={CATALOG_TABLE_IMAGE_SIZE}
          height={CATALOG_TABLE_IMAGE_SIZE}
          alt={name}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
