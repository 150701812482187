import { type ApiCategoryItem } from '../types'

const convertCategoryToOption = (categories: ApiCategoryItem) => {
  const { id, attributes } = categories

  const name = attributes.find((attribute) => {
    return attribute.is_name
  })?.value

  return {
    value: id,
    inputDisplay: name,
  }
}

export { convertCategoryToOption }
