import { EuiContextMenuItem, EuiForm, EuiHeaderLink } from '@elastic/eui'
import { BUTTON, MESSAGE, PLACEHOLDER } from '@shared/config'
import { FormError, NumberInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useUpdateAttributeGroup } from '../model'
import { type UpdateAttributeGroupProps } from './types'

export const UpdateAttributeGroup: ReactFc<UpdateAttributeGroupProps> = ({
  id,
  isHeader = false,
}) => {
  const { isOpen, showModal, closeModal, methods, updateGroup, isSubmitting } =
    useUpdateAttributeGroup(id)

  return (
    <>
      {(isHeader && (
        <EuiHeaderLink
          iconType="gear"
          color="primary"
          textProps={false}
          aria-label={BUTTON.EDIT}
          onClick={showModal}
        />
      )) || (
        <EuiContextMenuItem icon="pencil" size="s" onClick={showModal}>
          {BUTTON.EDIT}
        </EuiContextMenuItem>
      )}

      <FormProvider {...methods}>
        <Modal
          title={MESSAGE.EDIT_ATTRIBUTE_GROUP}
          submitButtonText={BUTTON.SAVE}
          onClose={closeModal}
          onSubmit={updateGroup}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <EuiForm component="form">
            <TextInput
              name="name"
              placeholder={PLACEHOLDER.ATTRIBUTE_GROUP_NAME}
              registerOptions={{ required: MESSAGE.ENTER_NAME }}
              inputProps={{
                disabled: isSubmitting,
              }}
              isRequired
            />
            <NumberInput
              name="order"
              placeholder={PLACEHOLDER.COUNT}
              inputProps={{
                disabled: isSubmitting,
              }}
            />

            <FormError />
          </EuiForm>
        </Modal>
      </FormProvider>
    </>
  )
}
