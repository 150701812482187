import { useState } from 'react'

export const useDialog = (hasOpen?: boolean) => {
  const [isOpen, setIsOpen] = useState(hasOpen ?? false)

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const handleOpenDialog = () => {
    setIsOpen(true)
  }

  return {
    isOpen,
    handleOpenDialog,
    handleCloseDialog,
  }
}
