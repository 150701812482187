import { useObjectGroupApi } from '@shared/api'
import { useMeta, useTitle } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AttributeGroupHeader, AttributeGroupTable } from '@widgets/settings'
import { useParams } from 'react-router-dom'

export const AttributeGroupPage: ReactFc = () => {
  const { id: attributeGroupIdParam = '' } = useParams()
  const attributeGroupId = parseInt(attributeGroupIdParam)
  const attributeGroupQuery = useObjectGroupApi(attributeGroupId)

  const attributeGroupTitle = useTitle(attributeGroupQuery)

  useMeta({ title: attributeGroupTitle })

  return (
    <Layout header={<AttributeGroupHeader />}>
      <AttributeGroupTable />
    </Layout>
  )
}
