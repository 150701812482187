import { type ApiAttribute } from '@shared/api/types'
import { MESSAGE } from '@shared/config'

import { AttributeGroupName } from './attribute-group-name'

const renderAttributeGroupName: RenderColumn<ApiAttribute, 'object_group'> = (groupId) => {
  if (!groupId) {
    return <>{MESSAGE.EMPTY_VALUE}</>
  }

  return <AttributeGroupName groupId={groupId} />
}

export { renderAttributeGroupName }
