export const baseTheme = {
  colors: {
    LIGHT: {
      primary: '#3e5aff',
    },
    DARK: {
      primary: '#3e5aff',
    },
  },
  base: 16,
  font: {
    family: 'Jost, sans-serif',
    weight: {
      regular: 400,
      medium: 400,
      semiBold: 500,
      bold: 500,
    },
  },
}
