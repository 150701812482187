import {
  API_ATTRIBUTE_MODEL,
  API_OBJECT_GROUP,
  API_QUERY_KEY,
  ATTRIBUTE_FLAGS,
  extractAttribute,
  useAttributeListApi,
  useObjectGroupListApi,
} from '@shared/api'
import { MESSAGE, ROUTES } from '@shared/config'
import { createNewObject } from '@shared/lib'

import { type AttributeGroup } from '../types'

export const useAttributeGroupsList = () => {
  const attributeGroupsQuery = useObjectGroupListApi(API_OBJECT_GROUP.ATTRIBUTE, [
    API_QUERY_KEY.ATTRIBUTE_GROUP_LIST,
  ])

  const attributeListQuery = useAttributeListApi({
    model: API_ATTRIBUTE_MODEL.SKU,
  })

  if (attributeGroupsQuery.data && attributeListQuery.data) {
    const attributeGroupsMap: Record<number, AttributeGroup> = createNewObject()

    // Извлекаем основную информацию о группе атрибутов
    attributeGroupsQuery.data.forEach((group) => {
      attributeGroupsMap[group.id] = {
        ...group,
        href: ROUTES.SETTINGS.ATTRIBUTE_GROUPS.GROUP.buildPath({ id: group.id }),
        name: extractAttribute(group, ATTRIBUTE_FLAGS.NAME)?.value || MESSAGE.EMPTY_NAME,
        count: 0,
        attributesIds: [],
        attributes: [],
      }
    })

    // Считаем количество атрибутов в группе
    attributeListQuery.data.results.forEach((attribute) => {
      if (attribute.object_group) {
        attributeGroupsMap[attribute.object_group].count += 1

        attributeGroupsMap[attribute.object_group].attributesIds = attributeGroupsMap[
          attribute.object_group
        ].attributesIds.concat(attribute.id)
      }
    })

    const data = Object.values(attributeGroupsMap)

    return {
      ...attributeGroupsQuery,
      data,
    }
  }

  return {
    ...attributeGroupsQuery,
    data: [],
  }
}
