import { App } from '@app/app'
import { EuiProvider } from '@elastic/eui'
import ReactDom from 'react-dom/client'

/**
 * React.StrictMode отключен:
 * Отключенпо причине не полной поддержки ElasticUI
 * https://github.com/elastic/eui/issues/7774
 */
ReactDom.createRoot(document.getElementById('root')!).render(
  <EuiProvider>
    <App />
  </EuiProvider>,
)
