import { EuiFieldPassword, EuiFormRow } from '@elastic/eui'
import { isFieldError } from '@shared/lib'
import { useFormContext } from 'react-hook-form'

import { type PasswordInputProps } from './types'

export const PasswordInput: ReactFc<PasswordInputProps> = ({
  name = 'password',
  placeholder = 'Пароль',
  registerOptions = {
    required: 'Введите пароль',
  },
  inputProps,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { onBlur, onChange, ref } = register(name, registerOptions)
  const error = errors?.password

  return (
    <EuiFormRow
      label={placeholder}
      isInvalid={!!error}
      error={isFieldError(error) && error.message}
    >
      <EuiFieldPassword
        placeholder={placeholder}
        isInvalid={!!error}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        {...inputProps}
      />
    </EuiFormRow>
  )
}
