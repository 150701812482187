import { EuiTextColor, useEuiTheme } from '@elastic/eui'

import { type Shade, type TextColorProps } from './types'

export const TextColor: ReactFc<TextColorProps> = ({ color, children, ...props }) => {
  const { euiTheme } = useEuiTheme()
  const shadeColors = euiTheme.colors as Record<Shade, string>

  return (
    <EuiTextColor color={shadeColors[color as Shade] || color} {...props}>
      {children}
    </EuiTextColor>
  )
}
