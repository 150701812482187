import { css } from '@emotion/react'
import { ELEMENTS_S_WIDTH } from '@shared/config'

export const grid = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${ELEMENTS_S_WIDTH}px, 1fr));
  gap: 0;
  align-items: start;
  padding: 0 1px;
`
