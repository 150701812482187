import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'

export const RulesPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.RULES.title })

  return (
    <div>
      <h1>Rules</h1>
    </div>
  )
}
