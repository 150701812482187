import { EuiPage, EuiPageBody } from '@elastic/eui'
import { useStyles } from '@shared/ui/styles'

import { getStyles } from './styles'
import { type LayoutProps } from './types'

export const Layout: ReactFc<React.PropsWithChildren<LayoutProps>> = ({
  sidebar,
  header,
  bottomBar,
  children,
  pageBodyProps,
}) => {
  const styles = useStyles(getStyles)

  return (
    <EuiPage grow css={styles.pageBg}>
      {sidebar}

      <EuiPageBody {...pageBodyProps} panelProps={{ hasShadow: false }}>
        {header}
        {children}
      </EuiPageBody>

      {bottomBar}
    </EuiPage>
  )
}
