import { css } from '@emotion/react'

export const list = css`
  .euiListGroupItem__label {
    width: 100%;
  }
  .euiListGroupItemExtraAction {
    margin-inline-end: 0;
  }
`
