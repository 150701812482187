import { EuiFormRow, EuiTextArea } from '@elastic/eui'
import { isFieldError } from '@shared/lib'
import { useFormContext } from 'react-hook-form'

import { type TextAreaProps } from './types'

const TextAreaInput: ReactFc<TextAreaProps> = ({
  name,
  icon,
  placeholder,
  registerOptions,
  inputProps,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const error = errors?.[name]

  const { onBlur, onChange, ref } = register(name, registerOptions)

  return (
    <EuiFormRow
      label={placeholder}
      isInvalid={!!error}
      error={isFieldError(error) && error.message}
    >
      <EuiTextArea
        icon={icon}
        placeholder={placeholder}
        isInvalid={!!error}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        {...inputProps}
      />
    </EuiFormRow>
  )
}

export { TextAreaInput }
