import { EuiButtonIcon, EuiPopover } from '@elastic/eui'
import { BUTTON } from '@shared/config'
import { useToggle } from '@shared/hooks'
import { type PropsWithChildren } from 'react'

const TableContextMenu: ReactFc<PropsWithChildren> = ({ children }) => {
  const [isMenuOpen, toggleMenu] = useToggle()

  return (
    <EuiPopover
      anchorPosition="leftCenter"
      button={
        <EuiButtonIcon
          iconType="boxesVertical"
          color="text"
          onClick={toggleMenu}
          aria-label={BUTTON.EDIT}
        />
      }
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
      panelPaddingSize="none"
    >
      {children}
    </EuiPopover>
  )
}

export { TableContextMenu }
