import { type EuiBasicTableColumn } from '@elastic/eui'

import { renderAttributeGroupsName } from '../lib'
import { type AttributeGroup } from '../types'

export const ATTRIBUTE_GROUPS_COLUMNS: EuiBasicTableColumn<AttributeGroup>[] = [
  {
    field: 'order',
    name: 'Позиция',
    width: '120px',
  },
  {
    field: 'name',
    name: 'Название',
    render: renderAttributeGroupsName,
  },
  {
    field: 'count',
    name: 'Атрибутов в группе',
  },
]
