import { type MouseEventHandler } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useGoBack = (): MouseEventHandler<HTMLAnchorElement> => {
  const navigate = useNavigate()
  const location = useLocation()

  return (event) => {
    event.preventDefault()

    if (window.history.length > 2) {
      navigate(-1)
    } else {
      const pathSegments = location.pathname.split('/').filter(Boolean)

      if (pathSegments.length > 0) {
        const newPath = '/' + pathSegments.slice(0, -1).join('/')
        navigate(newPath)
      } else {
        navigate('/')
      }
    }
  }
}
