import { API_QUERY_KEY_OPENAPI, queryClient } from '@shared/api'
import { $files } from '@shared/api/openapi-client'
import { useParams } from 'react-router-dom'

import { type UploadFileToAttributeValueArgs } from './types'

const useUploadAttributeValue = () => {
  const { id: objectIdParam = '' } = useParams()
  const objectId = parseInt(objectIdParam)

  const uploadFile = $files.useMutation('post', '/backend/api/files/v1/uploads/')

  const onSuccess = () => {
    queryClient.refetchQueries({
      queryKey: API_QUERY_KEY_OPENAPI.SKU(objectId),
    })
  }

  const uploadFileToAttributeValue = async ({
    attribute,
    files,
  }: UploadFileToAttributeValueArgs) => {
    // TODO: Добавить мультиаплоад файлов
    if (files && files.length > 0) {
      const formData = new FormData()
      formData.append('file', files[0])
      formData.append('sku_id', objectIdParam)
      formData.append('attribute_id', attribute.id.toString())

      uploadFile.mutate(
        {
          // @ts-ignore TODO: типы мутаций
          body: formData,
        },
        { onSuccess },
      )
    }
  }

  return {
    uploadFileToAttributeValue,
    isFileSubmitting: uploadFile.isPending,
    onSuccess,
  }
}

export { useUploadAttributeValue }
