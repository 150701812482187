import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { type ApiFile } from '@shared/api/types'
import { CATALOG_TABLE_IMAGE_SIZE } from '@shared/config'
import { FileImage, Image } from '@shared/ui/image'

const FileIcon: ReactFc<ApiFile> = (props) => {
  const { name, file, preview } = props

  return (
    <a href={file}>
      {(preview && (
        <Image
          src={preview}
          width={CATALOG_TABLE_IMAGE_SIZE}
          height={CATALOG_TABLE_IMAGE_SIZE}
          alt={name}
        />
      )) || (
        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            <FileImage width={CATALOG_TABLE_IMAGE_SIZE} height={CATALOG_TABLE_IMAGE_SIZE} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </a>
  )
}

export { FileIcon }
